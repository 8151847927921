export async function useScrollElementToView(elementId: string) {
  const element = document.getElementById(elementId);
  if (element) {
    await nextTick();
    let calculatedTop =
      element.getBoundingClientRect().top -
      document.body.getBoundingClientRect().top;
    const headerElement = document.getElementById("headerElement");
    if (headerElement) {
      calculatedTop -= headerElement.getBoundingClientRect().height + 20;
    }
    window.scrollTo({
      // behavior: 'smooth',
      top: calculatedTop,
    });
  }
}

export function showMaintenanceView(error = null) {
  let statusCode = 500;
  let statusMessage = "An error occurred";

  if (error !== null) {
    if (error.response) {
      statusCode = error.response.status;
      statusMessage = error.response.statusText;
    } else if (error.statusCode && error.statusMessage) {
      statusCode = error.statusCode;
      statusMessage = error.statusMessage;
    }
  }

  if (Number.isInteger(statusCode) && ![401, 404].includes(statusCode)) {
    if (import.meta.client) {
      const nuxtError = useError();
      nuxtError.value = {
        statusCode,
        statusMessage,
      };
    } else {
      throw createError({
        statusCode,
        statusMessage,
      });
    }
  }
}
